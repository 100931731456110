
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Customers } from '@/modules/customers';
import { IPatient, IPatientSearch, IPatientFilter, CustomerLinks } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class CustomersList extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  customers = $app.module(Customers);

  loading = false;
  showAppend = false;

  filter: IPatientFilter = $app.clone(this.customers.$store.PatientSearch.filter);

  get headers(): Array<ITableHeader> {
    return [
      {
        text: '',
        value: '',
        align: 'left',
        width: '50px',
        sortable: false
      },
      {
        text: $app.i18n('dplus.customers.PatientName'),
        value: 'PatientName',
        align: 'left',
        width: '30%'
      },
      {
        text: $app.i18n('system.Number'),
        value: 'PatientId',
        align: 'center',
        width: '80px'
      },
      {
        text: '',
        value: '',
        align: 'left',
        width: '50px',
        sortable: false
      },
      {
        text: $app.i18n('dplus.customers.Edit.PatientPhone'),
        value: 'PatientPhone',
        align: 'left',
        width: '15%'
      },
      {
        text: $app.i18n('dplus.customers.LastVisit'),
        value: 'LastVisit',
        align: 'center',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.customers.PatientDoctor'),
        value: 'PatientDoctorName',
        align: 'left',
        width: '13%'
      },
      {
        text: $app.i18n('dplus.customers.LastValueVIP'),
        value: 'LastValueVIP',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.customers.LastValue'),
        value: 'LastValue',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.customers.DebtValue'),
        value: 'DebtValue',
        align: 'right',
        width: '10%'
      }
    ];
  }

  get search(): IPatientSearch {
    return this.customers.$store.PatientSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.customers.$store.findPatients(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.customers.$store.PatientSearch, this.filter);
    this.customers.$store.setPatientSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  open(item: IPatient) {
    const route = this.$router.resolve({ name: CustomerLinks.CustomerEdit, params: { EntityId: '' + item.patientId } })
    window.open(route.href, '_blank');
  }

  append() {
    this.showAppend = true;
  }

  created() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
